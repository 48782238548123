import React from 'react'

import { Link } from 'gatsby'

function SiteFooter({children, className}) {
    return (
        <footer className={className}>
            {children}
            <div className="container">
                <div className="footerborder row d-flex justify-content-center align-items-center">
                    <div className="col-lg-7">
                        Eine Initiative der Bahá'í-Gemeinde in Deutschland K.d.ö.R.
                    </div>
                    <div className="col-lg-5">
                        <div className="footer-box-layout1">
                            <ul className="footer-social">
                                <li><Link to="/impressum/">Impressum</Link></li>
                                <li><Link to="/datenschutzerklaerung/">Datenschutzerklärung</Link></li>
                                {/* <li><OutboundLink to="#"><i className="fab fa-facebook-f"></i></OutboundLink></li>
                                <li><OutboundLink to="#"><i className="fab fa-twitter"></i></OutboundLink></li>
                                <li><OutboundLink to="#"><i className="fab fa-instagram"></i></OutboundLink></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer> 
    )
}

export default SiteFooter