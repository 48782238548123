import React from 'react'

import { Link } from 'gatsby'

function Navigation() {
    return (
        <nav id="dropdown" className="template-main-menu">
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/ueber-uns/">Über uns</Link>
                </li>
                <li>
                    <Link to="/archiv/">Archiv</Link>
                </li>
                <li>
                    <Link to="/kontakt/">Kontakt</Link>
                </li>
            </ul>
        </nav>
    )
}

export default Navigation