import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

import { Location } from '@reach/router'

function SEO({ title, description, image, pathname, article }) {
    return (
        <StaticQuery
            query={query}
            render={({
                site: {
                    siteMetadata: {
                        defaultTitle,
                        titleTemplate,
                        defaultDescription,
                        siteUrl,
                        defaultImage,
                        twitterUsername,
                    },
                },
            }) => {
                return (
                    <Location>
                        {({ location }) => {
                            const seo = {
                                title: title || defaultTitle,
                                description: description || defaultDescription,
                                image: `${siteUrl}${image || defaultImage}`,
                                url: `${siteUrl}${location.pathname || "/"}`,
                            }
                            return (
                                <Helmet title={seo.title} titleTemplate={seo.title === "Bahá'í in Deutschland" ? null : titleTemplate}>
                                    <html lang="de" />
                                    <meta name="description" content={seo.description} />
                                    <meta name="image" content={seo.image} />
                                    {seo.url && <meta property="og:url" content={seo.url} />}
                                    {(article ? true : null) && (
                                        <meta property="og:type" content="article" />
                                    )}
                                    {seo.title && <meta property="og:title" content={seo.title} />}
                                    {seo.description && (
                                        <meta property="og:description" content={seo.description} />
                                    )}
                                    {seo.image && <meta property="og:image" content={seo.image} />}
                                    <meta name="twitter:card" content="summary_large_image" />
                                    {twitterUsername && (
                                        <meta name="twitter:creator" content={twitterUsername} />
                                    )}
                                    {seo.title && <meta name="twitter:title" content={seo.title} />}
                                    {seo.description && (
                                        <meta name="twitter:description" content={seo.description} />
                                    )}
                                    {seo.image && <meta name="twitter:image" content={seo.image} />}
                                </Helmet>
                        )}}
                    </Location>
                )
            }}
        />
    )
}

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
