import React from 'react'

import Wrapper from './Wrapper'
import SiteFooter from './SiteFooter'
import { Link } from 'gatsby'
import Navigation from './Navigation'

import * as logo from '../images/logo.png'

function InnerPage({children, title, description, image}) {
    return (
        <Wrapper title={title} description={description} image={image}>
            <header>
                <div id="header-middlebar" className="pt--29 pb--29 bg--light">
                    <div className="container">
                        <div className="row d-flex align-items-center">
                            <div className="col-6 d-flex justify-content-start">
                                <div className="logo-area">
                                    <Link to="/" className="temp-logo" id="temp-logo">
                                        <img src={logo} alt="Jugend gestaltet Zukunft mit allen" className="img-fluid logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 d-flex justify-content-end">
                                <Navigation />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {children}
            <SiteFooter className="footer-wrap-layout1" />
        </Wrapper>
    )
}

export function Section({children}) {
    return (
        <section style={{
            padding: '5rem 0 2rem'
        }}>
            {children}
        </section>
    )
}

export default InnerPage