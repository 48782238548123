import React from 'react'
import { Link } from 'gatsby'

function PageHeader({ title }) {
    return (
        <section className="inner-page-banner bg-common">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="breadcrumbs-area">
                            <h1>{title}</h1>
                            <ul>
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>{title}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageHeader