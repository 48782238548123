import React, { Fragment } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import SEO from './SEO'

import '../styles/normalize.css'
import '../styles/main.css'
import '../styles/bootstrap.min.css'
import '../styles/fontawesome-all.min.css'
import '../styles/animate.min.css'
import '../styles/magnific-popup.css'
import '../styles/meanmenu.min.css'
import '../styles/main.scss'
import '../styles/yan_custom-css.css'

function Wrapper({ children, title, description, image }) {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <Fragment>
                    <Helmet><body className="bg-pearl box-layout sticky-header" /></Helmet>
                    <SEO title={title} description={description} image={image} />
                    <div id="wrapper" className="wrapper">
                        {children}
                    </div>
                </Fragment>
            )}
        />
    )
}

export default Wrapper
